import React from "react";
import "../../src/assets/main.css";

function CookiePolicy() {
  return (
    <div style={{ maxWidth: 838, height: "100%", margin: "0 auto" }}>
      <div
        style={{
          margin: "0px auto",
          fontFamily: "Roboto, Arial",
          padding: "50px 10px 100px",
        }}
      >
        <div data-custom-class="body">
          <div>
            <strong>
              <span style={{ fontSize: 26 }}>
                <span data-custom-class="title">COOKIE POLICY</span>
              </span>
            </strong>
          </div>
          <div>
            <br />
          </div>
          <div>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <strong>
                <span style={{ fontSize: 15 }}>
                  <span data-custom-class="subtitle">
                    Last updated <bdt class="question">August 27, 2021</bdt>
                  </span>
                </span>
              </strong>
            </span>
          </div>
          <div>
            <br />
          </div>
          <div>
            <br />
          </div>
          <div>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  This Cookie Policy explains how{" "}
                  <bdt class="question">Guest Experience Maximization LLC</bdt>
                  <bdt class="block-component"></bdt> ("
                  <bdt class="block-component"></bdt>
                  <strong>Company</strong>
                  <bdt class="statement-end-if-in-editor"></bdt>", "
                  <strong>we</strong>", "<strong>us</strong>", and "
                  <strong>our</strong>") uses cookies and similar technologies
                  to recognize you when you visit our websites at
                  <bdt class="forloop-component"></bdt>{" "}
                  <bdt class="question">
                    <a
                      href="http://wandry.app"
                      target="_blank"
                      data-custom-class="link"
                    >
                      http://wandry.app
                    </a>
                  </bdt>
                  ,<bdt class="forloop-component"></bdt> ("
                  <strong>Websites</strong>"). It explains what these
                  technologies are and why we use them, as well as your rights
                  to control our use of them.<bdt class="block-component"></bdt>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  In some cases we may use cookies to collect personal
                  information, or that becomes personal information if we
                  combine it with other information.{" "}
                  <bdt class="block-component"></bdt>
                </span>
                <bdt class="statement-end-if-in-editor">
                  <span data-custom-class="body_text"></span>
                </bdt>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
                <strong>
                  <span data-custom-class="heading_1">What are cookies?</span>
                </strong>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  Cookies are small data files that are placed on your computer
                  or mobile device when you visit a website. Cookies are widely
                  used by website owners in order to make their websites work,
                  or to work more efficiently, as well as to provide reporting
                  information.
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  Cookies set by the website owner (in this case,{" "}
                  <bdt class="question">Guest Experience Maximization LLC</bdt>)
                  are called "first party cookies". Cookies set by parties other
                  than the website owner are called "third party cookies". Third
                  party cookies enable third party features or functionality to
                  be provided on or through the website (e.g. like advertising,
                  interactive content and analytics). The parties that set these
                  third party cookies can recognize your computer both when it
                  visits the website in question and also when it visits certain
                  other websites.
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
                <strong>
                  <span data-custom-class="heading_1">
                    Why do we use cookies?
                  </span>
                </strong>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  We use first<bdt class="block-component"></bdt> and third
                  <bdt class="statement-end-if-in-editor"></bdt> party cookies
                  for several reasons. Some cookies are required for technical
                  reasons in order for our Websites to operate, and we refer to
                  these as "essential" or "strictly necessary" cookies. Other
                  cookies also enable us to track and target the interests of
                  our users to enhance the experience on our Online Properties.{" "}
                  <bdt class="block-component"></bdt>Third parties serve cookies
                  through our Websites for advertising, analytics and other
                  purposes. <bdt class="statement-end-if-in-editor"></bdt>This
                  is described in more detail below.
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  The specific types of first<bdt class="block-component"></bdt>{" "}
                  and third<bdt class="statement-end-if-in-editor"></bdt> party
                  cookies served through our Websites and the purposes they
                  perform are described below (please note that the specific
                  cookies served may vary depending on the specific Online
                  Properties you visit):
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>
                        <span data-custom-class="heading_1">
                          How can I control cookies?
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  You have the right to decide whether to accept or reject
                  cookies. You can exercise your cookie rights by setting your
                  preferences in the Cookie Consent Manager. The Cookie Consent
                  Manager allows you to select which categories of cookies you
                  accept or reject. Essential cookies cannot be rejected as they
                  are strictly necessary to provide you with services.
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  The Cookie Consent Manager can be found in the notification
                  banner and on our website. If you choose to reject cookies,
                  you may still use our website though your access to some
                  functionality and areas of our website may be restricted. You
                  may also set or amend your web browser controls to accept or
                  refuse cookies. As the means by which you can refuse cookies
                  through your web browser controls vary from
                  browser-to-browser, you should visit your browser's help menu
                  for more information.
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  In addition, most advertising networks offer you a way to opt
                  out of targeted advertising. If you would like to find out
                  more information, please visit&nbsp;
                </span>
              </span>
              <span style={{ fontSize: 15, color: "rgb(48, 48, 241)" }}>
                <span data-custom-class="body_text">
                  <a
                    data-custom-class="link"
                    href="http://www.aboutads.info/choices/"
                    target="_blank"
                  >
                    http://www.aboutads.info/choices/
                  </a>
                </span>
              </span>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">&nbsp;or&nbsp;</span>
              </span>
              <span style={{ fontSize: 15, color: "rgb(48, 48, 241)" }}>
                <span data-custom-class="body_text">
                  <a
                    data-custom-class="link"
                    data-fr-linked="true"
                    href="http://www.youronlinechoices.com"
                    target="_blank"
                  >
                    http://www.youronlinechoices.com
                  </a>
                </span>
              </span>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">.</span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  The specific types of first and third party cookies served
                  through our Websites and the purposes they perform are
                  described in the table below (please note that the
                  specific&nbsp;
                </span>
                <span data-custom-class="body_text">
                  cookies served may vary depending on the specific Online
                  Properties you visit):
                </span>
              </span>
            </span>
            <span style={{ fontSize: 15 }}></span>
            <span
              data-custom-class="heading_2"
              style={{ color: "rgb(0, 0, 0)" }}
            >
              <span style={{ fontSize: 15 }}>
                <strong>
                  <u>
                    <br />
                    <br />
                    Essential website cookies:
                  </u>
                </strong>
              </span>
            </span>
          </div>
          <div>
            <div>
              <br />
            </div>
            <div style={{ lineHeight: 1.5 }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    These cookies are strictly necessary to provide you with
                    services available through our Websites and to use some of
                    its features, such as access to secure areas.
                  </span>
                </span>
              </span>
            </div>
            <p style={{ fontSize: 15, lineHeight: 1.5 }}>
              <span data-custom-class="body_text"></span>
            </p>
            <section
              data-custom-class="body_text"
              style={{
                width: "100%",
                border: "1px solid rgb(230, 230, 230)",
                margin: "0px 0px 10px",
                borderRadius: 3,
                textAlign: "left",
              }}
            >
              <div
                style={{
                  padding: "8px 13px",
                  borderBottom: "1px solid #e6e6e6",
                }}
              >
                <table>
                  <tbody>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: 1.67,
                        margin: "0 0 8px",
                        verticalAlign: "top",
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80,
                        }}
                      >
                        Name:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span
                          style={{ color: "#8b93a7", wordBreak: "break-all" }}
                        >
                          __tlbcpv
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: 1.67,
                        margin: 0,
                        verticalAlign: "top",
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80,
                        }}
                      >
                        Purpose:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span
                          style={{ color: "#8b93a7", wordBreak: "break-all" }}
                        >
                          Used to record unique visitor views of the consent
                          banner.
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: 1.67,
                        margin: "0 0 8px",
                        verticalAlign: "top",
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80,
                        }}
                      >
                        Provider:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span
                          style={{ color: "#8b93a7", wordBreak: "break-all" }}
                        >
                          .termly.io
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: 1.67,
                        margin: "0 0 8px",
                        verticalAlign: "top",
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80,
                        }}
                      >
                        Service:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span
                          style={{ color: "#8b93a7", wordBreak: "break-all" }}
                        >
                          Termly{" "}
                          <a
                            href="https://termly.io/our-privacy-policy/"
                            style={{ color: "#1a98eb !important" }}
                            target="_blank"
                          >
                            View Service Privacy Policy
                          </a>{" "}
                          &nbsp;
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: 1.67,
                        margin: "0 0 8px",
                        verticalAlign: "top",
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80,
                        }}
                      >
                        Country:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span
                          style={{ color: "#8b93a7", wordBreak: "break-all" }}
                        >
                          United States
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: 1.67,
                        margin: "0 0 8px",
                        verticalAlign: "top",
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80,
                        }}
                      >
                        Type:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span
                          style={{ color: "#8b93a7", wordBreak: "break-all" }}
                        >
                          http_cookie
                        </span>
                      </td>
                    </tr>
                    <tr
                      style={{
                        fontFamily: "Roboto, Arial",
                        fontSize: 12,
                        lineHeight: 1.67,
                        margin: "0 0 8px",
                        verticalAlign: "top",
                      }}
                    >
                      <td
                        style={{
                          textAlign: "right",
                          color: "#19243c",
                          minWidth: 80,
                        }}
                      >
                        Expires in:
                      </td>
                      <td style={{ display: "inline-block", marginLeft: 5 }}>
                        <span
                          style={{ color: "#8b93a7", wordBreak: "break-all" }}
                        >
                          1 year
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
          <div></div>
          <div style={{ lineHeight: 1.5 }}>
            <span
              data-custom-class="heading_2"
              style={{ color: "rgb(0, 0, 0)" }}
            >
              <span style={{ fontSize: 15 }}>
                <strong>
                  <u>
                    <br />
                    Performance and functionality cookies:
                  </u>
                </strong>
              </span>
            </span>
          </div>
          <div>
            <p style={{ fontSize: 15, lineHeight: 1.5 }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  These cookies are used to enhance the performance and
                  functionality of our Websites but are non-essential to their
                  use. However, without these cookies, certain functionality
                  (like videos) may become unavailable.
                </span>
              </span>
            </p>
            <div>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <section
                    data-custom-class="body_text"
                    style={{
                      width: "100%",
                      border: "1px solid #e6e6e6",
                      margin: "0 0 10px",
                      borderRadius: 3,
                    }}
                  >
                    <div
                      style={{
                        padding: "8px 13px",
                        borderBottom: "1px solid #e6e6e6",
                      }}
                    >
                      <table>
                        <tbody>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: 1.67,
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: 80,
                              }}
                            >
                              Name:
                            </td>
                            <td
                              style={{ display: "inline-block", marginLeft: 5 }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                _hjAbsoluteSessionInProgress
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: 1.67,
                              margin: 0,
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: 80,
                              }}
                            >
                              Purpose:
                            </td>
                            <td
                              style={{ display: "inline-block", marginLeft: 5 }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                The cookie is set so Hotjar can track the
                                beginning of the user's journey for a total
                                session count. It does not contain any
                                identifiable information.
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: 1.67,
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: 80,
                              }}
                            >
                              Provider:
                            </td>
                            <td
                              style={{ display: "inline-block", marginLeft: 5 }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                .wandry.app
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: 1.67,
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: 80,
                              }}
                            >
                              Service:
                            </td>
                            <td
                              style={{ display: "inline-block", marginLeft: 5 }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                Hotjar{" "}
                                <a
                                  href="https://www.hotjar.com/legal/compliance/opt-out"
                                  style={{ color: "#1a98eb !important" }}
                                  target="_blank"
                                >
                                  View Service Privacy Policy
                                </a>{" "}
                                &nbsp;
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: 1.67,
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: 80,
                              }}
                            >
                              Country:
                            </td>
                            <td
                              style={{ display: "inline-block", marginLeft: 5 }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                United States
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: 1.67,
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: 80,
                              }}
                            >
                              Type:
                            </td>
                            <td
                              style={{ display: "inline-block", marginLeft: 5 }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                http_cookie
                              </span>
                            </td>
                          </tr>
                          <tr
                            style={{
                              fontFamily: "Roboto, Arial",
                              fontSize: 12,
                              lineHeight: 1.67,
                              margin: "0 0 8px",
                              verticalAlign: "top",
                            }}
                          >
                            <td
                              style={{
                                textAlign: "right",
                                color: "#19243c",
                                minWidth: 80,
                              }}
                            >
                              Expires in:
                            </td>
                            <td
                              style={{ display: "inline-block", marginLeft: 5 }}
                            >
                              <span
                                style={{
                                  color: "#8b93a7",
                                  wordBreak: "break-all",
                                }}
                              >
                                30 minutes
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </section>
                </span>
              </span>
            </div>
          </div>
          <div>
            <div style={{ lineHeight: 1.5 }}>
              <span
                data-custom-class="heading_2"
                style={{ color: "rgb(0, 0, 0)" }}
              >
                <span style={{ fontSize: 15 }}>
                  <strong>
                    <u>
                      <br />
                      Analytics and customization cookies:
                    </u>
                  </strong>
                </span>
              </span>
            </div>
            <p style={{ fontSize: 15, lineHeight: 1.5 }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    These cookies collect information that is used either in
                    aggregate form to help us understand how our Websites are
                    being used or how effective our marketing campaigns are, or
                    to help us customize our Websites for you.
                  </span>
                </span>
              </span>
            </p>
            <div>
              <span data-custom-class="body_text">
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              _gat
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              Used to monitor number of Google Analytics server
                              requests when using Google Tag Manager
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              .wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              Google Analytics{" "}
                              <a
                                href="https://policies.google.com/technologies/ads"
                                style={{ color: "#1a98eb !important" }}
                                target="_blank"
                              >
                                View Service Privacy Policy
                              </a>{" "}
                              &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              http_cookie
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              1 minute
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              #collect
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              Sends data such as visitor’s behavior and device
                              to Google Analytics. It is able to keep track of
                              the visitor across marketing channels and devices.
                              It is a pixel tracker type cookie whose activity
                              lasts within the browsing session.
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              get.wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              Google Analytics{" "}
                              <a
                                href="https://policies.google.com/technologies/ads"
                                style={{ color: "#1a98eb !important" }}
                                target="_blank"
                              >
                                View Service Privacy Policy
                              </a>{" "}
                              &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              pixel_tracker
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              session
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              _hjid
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              This cookie is set when the customer first lands
                              on a page with the Hotjar script. It is used to
                              persist the Hotjar User ID, unique to that site on
                              the browser. This ensures that behavior in
                              subsequent visits to the same site will be
                              attributed to the same user ID.
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              get.wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              hotjar{" "}
                              <a
                                href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies"
                                style={{ color: "#1a98eb !important" }}
                                target="_blank"
                              >
                                View Service Privacy Policy
                              </a>{" "}
                              &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              html_local_storage
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              persistent
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              i
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              Used by Yandex Metrica for identifying site users.
                              This cookie exists for a period of 1 year maximum.
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              get.wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              Yandex Metrica{" "}
                              <a
                                href="https://yandex.com/legal/privacy/"
                                style={{ color: "#1a98eb !important" }}
                                target="_blank"
                              >
                                View Service Privacy Policy
                              </a>{" "}
                              &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              pixel_tracker
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              session
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              _hjid
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              This cookie is set when the customer first lands
                              on a page with the Hotjar script. It is used to
                              persist the Hotjar User ID, unique to that site on
                              the browser. This ensures that behavior in
                              subsequent visits to the same site will be
                              attributed to the same user ID.
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              .wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              hotjar{" "}
                              <a
                                href="https://help.hotjar.com/hc/en-us/articles/115011789248-Hotjar-Cookies"
                                style={{ color: "#1a98eb !important" }}
                                target="_blank"
                              >
                                View Service Privacy Policy
                              </a>{" "}
                              &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              http_cookie
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              11 months 30 days
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              _ga
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              It records a particular ID used to come up with
                              data about website usage by the user. It is a HTTP
                              cookie that expires after 2 years.
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              .wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              Google Analytics{" "}
                              <a
                                href="https://policies.google.com/technologies/ads"
                                style={{ color: "#1a98eb !important" }}
                                target="_blank"
                              >
                                View Service Privacy Policy
                              </a>{" "}
                              &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              http_cookie
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              1 year 11 months 29 days
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              _ga_#
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              Used to distinguish individual users by means of
                              designation of a randomly generated number as
                              client identifier, which allows calculation of
                              visits and sessions
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              .wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              Google analytics{" "}
                              <a
                                href="https://policies.google.com/technologies/ads"
                                style={{ color: "#1a98eb !important" }}
                                target="_blank"
                              >
                                View Service Privacy Policy
                              </a>{" "}
                              &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              http_cookie
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              1 year 11 months 29 days
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              _gid
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              Keeps an entry of unique ID which is then used to
                              come up with statistical data on website usage by
                              visitors. It is a HTTP cookie type and expires
                              after a browsing session.
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              .wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              Google Analytics{" "}
                              <a
                                href="https://policies.google.com/technologies/ads"
                                style={{ color: "#1a98eb !important" }}
                                target="_blank"
                              >
                                View Service Privacy Policy
                              </a>{" "}
                              &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              http_cookie
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              1 day
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              </span>
            </div>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span
              data-custom-class="heading_2"
              style={{ color: "rgb(0, 0, 0)" }}
            >
              <span style={{ fontSize: 15 }}>
                <strong>
                  <u>
                    <br />
                    Advertising cookies:
                  </u>
                </strong>
              </span>
            </span>
          </div>
          <div>
            <p style={{ fontSize: 15, lineHeight: 1.5 }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    These cookies are used to make advertising messages more
                    relevant to you. They perform functions like preventing the
                    same ad from continuously reappearing, ensuring that ads are
                    properly displayed for advertisers, and in some cases
                    selecting advertisements that are based on your interests.
                  </span>
                </span>
              </span>
            </p>
            <div>
              <span data-custom-class="body_text">
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              _fbp
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              Facebook tracking pixel used to identify visitors
                              for personalized advertising.
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              .wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              Facebook{" "}
                              <a
                                href="https://www.facebook.com/privacy/explanation"
                                style={{ color: "#1a98eb !important" }}
                                target="_blank"
                              >
                                View Service Privacy Policy
                              </a>{" "}
                              &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              http_cookie
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              2 months 29 days
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              </span>
            </div>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span
              data-custom-class="heading_2"
              style={{ color: "rgb(0, 0, 0)" }}
            >
              <span style={{ fontSize: 15 }}>
                <strong>
                  <u>
                    <br />
                    Unclassified cookies:
                  </u>
                </strong>
              </span>
            </span>
          </div>
          <div>
            <p style={{ fontSize: 15, lineHeight: 1.5 }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span data-custom-class="body_text">
                    These are cookies that have not yet been categorized. We are
                    in the process of classifying these cookies with the help of
                    their providers.
                  </span>
                </span>
              </span>
            </p>
            <div>
              <span data-custom-class="body_text">
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              _sp_ses.c36d.expires
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              get.wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________ &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              html_local_storage
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              persistent
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              _hjFirstSeen
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              .wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________ &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              http_cookie
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              30 minutes
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              ubvs
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              get.wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________ &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              http_cookie
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              5 months 27 days
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              ubpv
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              get.wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________ &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              http_cookie
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              6 months 1 day
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              _sp_ses.c36d
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              get.wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________ &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              html_local_storage
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              persistent
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              _sp_id.c36d.expires
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              get.wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________ &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              html_local_storage
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              persistent
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              ubvt
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              .wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________ &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              server_cookie
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              3 days
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              _hjIncludedInPageviewSample
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              get.wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________ &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              http_cookie
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              2 minutes
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              _sp_id.c36d
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              get.wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________ &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              html_local_storage
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              persistent
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              snowplowOutQueue_ubSnowplow_sp-ub_get.expires
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              get.wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________ &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              html_local_storage
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              persistent
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
                <section
                  data-custom-class="body_text"
                  style={{
                    width: "100%",
                    border: "1px solid #e6e6e6",
                    margin: "0 0 10px",
                    borderRadius: 3,
                  }}
                >
                  <div
                    style={{
                      padding: "8px 13px",
                      borderBottom: "1px solid #e6e6e6",
                    }}
                  >
                    <table>
                      <tbody>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Name:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              snowplowOutQueue_ubSnowplow_sp-ub_get
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: 0,
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Purpose:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Provider:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              get.wandry.app
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Service:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              __________ &nbsp;
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Country:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              United States
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Type:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              html_local_storage
                            </span>
                          </td>
                        </tr>
                        <tr
                          style={{
                            fontFamily: "Roboto, Arial",
                            fontSize: 12,
                            lineHeight: 1.67,
                            margin: "0 0 8px",
                            verticalAlign: "top",
                          }}
                        >
                          <td
                            style={{
                              textAlign: "right",
                              color: "#19243c",
                              minWidth: 80,
                            }}
                          >
                            Expires in:
                          </td>
                          <td
                            style={{ display: "inline-block", marginLeft: 5 }}
                          >
                            <span
                              style={{
                                color: "#8b93a7",
                                wordBreak: "break-all",
                              }}
                            >
                              persistent
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </section>
              </span>
            </div>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(0, 0, 0)", fontSize: 15 }}>
                <strong>
                  <span data-custom-class="heading_1">
                    What about other tracking technologies, like web beacons?
                  </span>
                </strong>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span data-custom-class="body_text">
                  Cookies are not the only way&nbsp;
                </span>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    to recognize or track visitors to a website. We may use
                    other, similar technologies from time to time, like web
                    beacons (sometimes called "tracking pixels" or "clear
                    gifs"). These are tiny graphics files that contain a unique
                    identifier that enable us to recognize when someone has
                    visited our Websites
                    <bdt class="block-component"></bdt> or opened an e-mail
                    including them<bdt class="statement-end-if-in-editor"></bdt>
                    . This allows us, for example, to monitor&nbsp;
                  </span>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span data-custom-class="body_text">
                        the traffic patterns of users from one page within a
                        website to another, to deliver or communicate with
                        cookies, to understand whether you have come to the
                        website from an online advertisement displayed on a
                        third-party website, to improve site performance, and to
                        measure the success of e-mail marketing campaigns. In
                        many instances, these technologies are reliant on
                        cookies to function properly, and so declining cookies
                        will impair their functioning.
                      </span>
                      <bdt class="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>
                        <span data-custom-class="heading_1">
                          Do you use Flash cookies or Local Shared Objects?
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Websites may also use so-called "Flash Cookies" (also known as
                Local Shared Objects or "LSOs") to, among other things, collect
                and store information about your use of our services, fraud
                prevention and for other site operations.
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                If you do not want Flash Cookies stored on your computer, you
                can adjust the settings of your Flash player to block Flash
                Cookies storage using the tools contained in the&nbsp;
              </span>
            </span>
            <span data-custom-class="body_text">
              <span style={{ color: "rgb(48, 48, 241)" }}>
                <a
                  data-custom-class="link"
                  href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
                  target="_BLANK"
                >
                  <span style={{ fontSize: 15 }}>
                    Website Storage Settings Panel
                  </span>
                </a>
              </span>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                . You can also control Flash Cookies by going to the&nbsp;
              </span>
              <span style={{ color: "rgb(48, 48, 241)" }}>
                <a
                  data-custom-class="link"
                  href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
                  target="_BLANK"
                >
                  <span style={{ fontSize: 15 }}>
                    Global Storage Settings Panel
                  </span>
                </a>
              </span>
            </span>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">&nbsp;and&nbsp;</span>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  following the instructions (which may include instructions
                  that explain, for example, how to delete existing Flash
                  Cookies (referred to "information" on the Macromedia site),
                  how to prevent Flash LSOs from being placed on your computer
                  without your being asked, and (for Flash Player 8 and later)
                  how to block Flash Cookies that are not being delivered by the
                  operator of the page you are on at the time).
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  Please note that setting the Flash Player to restrict or limit
                  acceptance of Flash Cookies may reduce or impede the
                  functionality of some Flash applications, including,
                  potentially, Flash applications used in connection with our
                  services or online content.
                </span>
              </span>
            </span>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt class="statement-end-if-in-editor"></bdt>
                      <bdt class="block-component"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                          <strong>
                            <span data-custom-class="heading_1">
                              Do you serve targeted advertising?
                            </span>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span data-custom-class="body_text">
                Third parties may serve cookies on your computer or mobile
                device to serve advertising through our Websites. These
                companies may use information about your visits to this and
                other websites in order to provide relevant advertisements about
                goods and services that you may be interested in. They may also
                employ technology that is used to measure the effectiveness of
                advertisements. This can be accomplished by them using cookies
                or web beacons to collect information about your visits to this
                and other sites in order to provide relevant advertisements
                about goods and services of potential interest to you. The
                information collected through this process does not enable us or
                them to identify your name, contact details or other details
                that directly identify you unless you choose to provide these.
              </span>
            </span>
            <span style={{ color: "rgb(127, 127, 127)" }}>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <bdt class="statement-end-if-in-editor"></bdt>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                <strong>
                  <span data-custom-class="heading_1">
                    How often will you update this Cookie Policy?
                  </span>
                </strong>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">We may update&nbsp;</span>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    this Cookie Policy from time to time in order to reflect,
                    for example, changes to the cookies we use or for other
                    operational, legal or regulatory reasons. Please therefore
                    re-visit this Cookie Policy regularly to stay informed about
                    our use of cookies and related technologies.
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    The date at the top of this Cookie Policy indicates when it
                    was last updated.
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(0, 0, 0)" }}>
                  <strong>
                    <span data-custom-class="heading_1">
                      Where can I get further information?
                    </span>
                  </strong>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <br />
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span data-custom-class="body_text">
                    If you have any questions about our use of cookies or other
                    technologies, please email us at{" "}
                    <bdt class="question">ftorres@gemax.io</bdt> or by post to:
                  </span>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span data-custom-class="body_text">
              <br />
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <span data-custom-class="body_text">
                  <bdt class="question">Guest Experience Maximization LLC</bdt>
                </span>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
              <bdt class="question">
                <span data-custom-class="body_text">
                  5728 Major Blvd. Suite 200
                </span>
              </bdt>
              <span data-custom-class="body_text">
                <bdt class="block-component"></bdt>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <bdt class="question">Orlando</bdt>
                <bdt class="block-component"></bdt>
                <bdt class="block-component"></bdt>,{" "}
                <bdt class="question">FL</bdt>
                <bdt class="statement-end-if-in-editor"></bdt>
                <bdt class="block-component"></bdt>
                <bdt class="block-component"></bdt>{" "}
                <bdt class="question">32819</bdt>
                <bdt class="statement-end-if-in-editor"></bdt>
                <bdt class="block-component"></bdt>
                <bdt class="block-component"></bdt>
                <bdt class="block-component"></bdt>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <bdt class="question">United States</bdt>
                <bdt class="statement-end-if-in-editor"></bdt>
                <bdt class="else-block"></bdt>
              </span>
            </span>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                <bdt class="statement-end-if-in-editor"></bdt>
              </span>
            </span>
          </div>
          <div style={{ lineHeight: 1.5 }}>
            <span data-custom-class="body_text">
              <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                Phone: <bdt class="question">407-496-7796</bdt>
                <bdt class="block-component"></bdt>
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CookiePolicy;
